import React, {
  Component
} from 'react';

import {
   Home
} from './Index';

class App extends Component {
  render() {
    return (
      <div>
        <Home />
      </div>
    );

  }
}

export default App;