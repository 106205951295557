import React from 'react';

const element =
    <section>
		<div className="line">Work</div>
	</section>

export const Line = () => {
    return (
        element
    )
};